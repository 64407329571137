.flexible-tout {
  background-color: $Mashed-Potato-Gray;
  color: $Brand-Black;
  border-radius: 8px;
  padding: 32px 16px;

  @media #{$breakpoint-above-tablet} {
    padding: 32px 104px;
  }

  &.bgBlack {
    background-color: $Brand-Black;
    color: $white;
  }
  &.bgGray {
    background-color: $Mashed-Potato-Gray;
    color: $Brand-Black;
  }
  &.bgRed {
    background-color: $Brand-Red;
    color: $white;
  }

  &--container {
    padding: 16px;
    background-color: $Brand-Black;

    @media #{$breakpoint-above-tablet} {
      padding: 40px;
    }

    &.bgBlack {
      background-color: $Brand-Black;
    }
    &.bgGray {
      background-color: $Mashed-Potato-Gray;
    }
    &.bgRed {
      background-color: $Brand-Red;
    }
  }

  &--columns {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media #{$breakpoint-above-tablet} {
      flex-direction: row;
    }
  }

  &--column {
    display: flex;

    &--1 {
      gap: 16px;
      margin-left: 96px;
      @media #{$breakpoint-above-tablet} {
        align-items: center;
        gap: 24px;
        margin-left: 104px;
      }
    }

    &--2 {
      justify-content: center;
      align-items: center;
    }
  }

  &--headline {
    margin-bottom: 0;
    font-family: $National2Condensed;
    font-size: 2rem;
    font-weight: 700;
    text-transform: uppercase;

    @media #{$breakpoint-above-tablet} {
      font-size: 2.5rem;
    }
  }

  &--text {
    font-size: 14px;
    line-height: 21px;

    @media #{$breakpoint-above-tablet} {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &--pictogram-wrapper {
    float: left;
    margin-left: -96px;
    padding-top: 4px;

    @media #{$breakpoint-above-tablet} {
      margin-left: -104px;
      padding-top: 0;
    }
  }

  &--button-container {
    margin-top: 32px;
    margin-bottom: 0;

    @media #{$breakpoint-above-tablet} {
      margin-top: 0;
    }
  }

  &--button {
    height: 44px;
    text-decoration: none;
    font-size: 16px;
    line-height: 2.75rem;
  }
}
