@import 'AU/_checkoutPage';

/* stylelint-disable */
@mixin commonFonts {
  font-family: $National2Condensed;
  font-style: normal;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: $Brand-Black;
}

@mixin normalFont {
  font-family: $National2Regular;
  font-style: normal;
  text-transform: none;
  font-size: small;
}

@mixin payButtonMixin {
  .payButton {
    font-family: $National2Regular;
    background-color: #dbdbdb;
    border: none;
    border-radius: 2rem;
    padding: 0.9rem 0.9rem;
    text-transform: none;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    width: 100%;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
    cursor: no-drop;

    @media #{$mobile-only} {
      padding-top: 14px;
      width: 100%;
      font-size: 15px;
    }

    .rupee-text {
      margin-left: 15px;

      @media #{$mobile-only} {
        margin-left: 35px;
      }

      @media #{$breakpoint-below-iphone5} {
        margin-left: 20px;
      }
    }

    &.enabled {
      background-color: #e4002b;
      border: 1px solid #e4002b;
      color: #fff;
      cursor: pointer;
    }
  }
}

@mixin commonChecks {
  margin-top: 5px;
  margin-right: 15px;
  min-width: 1rem;
  min-height: 1rem;
}

.headerdivider {
  margin: 0%;

  @media screen and (max-width: 1023px) {
    margin-bottom: -4px;
  }
}

.checkoutContainer {
  &.mobile {
    width: 100%;
    margin: 0;
  }

  width: 75%;
  margin: auto;

  .checkoutHeader {
    .checkoutTitle {
      font-size: 3rem;
      font-family: $National2Condensed;
      font-style: normal;
      font-weight: bold;
      text-transform: uppercase;
      color: $Brand-Black;

      &.mobile {
        font-size: 2rem;

        @media #{$mobile-only} {
          font-size: 1.5rem;
        }
      }
    }

    .secure-checkout {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $Brand-Black;

      & > span {
        margin-left: 0.625rem;
        font-weight: bold;
      }

      @media #{$mobile-only} {
        font-size: 12px;
        align-items: baseline;

        & .checkout-lock-icon {
          display: inline-block;
          font-size: 16px;
          position: relative;
          top: 3px;
        }

        & > span {
          margin-left: 4px;
          align-items: baseline;
        }
      }
    }
  }

  .dynamicmenu-alert {
    max-width: 99%;
    color: $Brand-Black !important;
    .dynamicmenu-alert-message {
      color: $Brand-Black !important;
    }
    .dynamicmenu-alert-cart-text {
      font-family: 'National 2 Regular';
      text-decoration: underline;
      color: $Brand-Black !important;
    }
  }

  .notify-alert-guest-checkout {
    font-family: var(--fontFamilyRegular);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.1px;
    background: #fff6e3;
    border-radius: 4px;
    display: flex;
    padding: 15px;
    width: 100%;
    margin-bottom: 1%;
  }
  .guest-Checkout-warning-msg {
    padding-left: 5px;
  }

  .checkoutBody {
    margin: 0 24px;

    .Toastify {
      & .Toastify__toast-container {
        & .guest-otp-error {
          background-color: #fff4f2;
          box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.15);
          border-radius: 3px;
          width: 498px;
          height: 54px;
          @media #{$breakpoint-below-tablet} {
            width: 100%;
          }
          & .img-text-div {
            display: flex;
            flex-direction: row;
            position: relative;
            & .text {
              color: #202124;
              letter-spacing: -0.1px;
              line-height: 24px;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              font-family: $National2Regular;
              padding-left: 5px;
            }
          }
        }
      }
    }

    &.mobile {
      margin: 0;
    }

    .checkoutDescDetails {
      .row {
        background-color: #f8f7f5;
      }

      .giftcardRow,
      .creditCardRow {
        background-color: white;
        display: flex;

        .material-input.field input:placeholder-shown + label {
          background-color: white;
        }
      }

      .labelContent {
        @include commonFonts();
        font-size: 24px;
        line-height: 25px;
        letter-spacing: 0.5px;

        p {
          font-size: 18px;
          margin-bottom: 0;
          margin-top: 0;
        }
      }

      button.checkout-sing-in {
        font-family: $National2Regular;
        text-transform: none;
        padding: 0.5rem 1rem;
        border-radius: 200px;
        line-height: 1;
        border: 2px solid #000;
        font-weight: bold;
        margin: 0 -55px;
      }

      button.checkout-link-btn {
        font-family: var(--fontFamilyRegular);
        font-style: normal;
        text-transform: none;
        font-size: small;
        text-decoration-skip-ink: none;
        font-size: 14px;
        text-decoration: underline;
        font-weight: bold;
        padding: 0;
        box-shadow: none;
        color: #202124;
        border: none;
        background: #f8f7f5;
      }

      .labelContent-checkout-options {
        @include commonFonts();

        margin-top: 40px;
        padding-left: 40px;
        margin-left: 0;
        padding-right: 0;
        margin-bottom: 0;
        padding-bottom: 132px;
        height: 157px;
        width: 178px;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.5px;

        &.type-dine-in {
          padding-bottom: 50px;
          height: auto;
        }

        @media screen and (max-width: 575px) {
          padding-left: 20px;
          padding-right: 10px;
          padding-bottom: 0;
          height: 24px;
          width: 395px;
          margin-top: 30px;

          &.type-dine-in {
            padding-bottom: 0px;
            height: 24px;
          }
        }

        &.pickup-collection-method-label {
          @media #{$breakpoint-below-tablet} {
            padding-left: 20px;
            padding-right: 10px;
            padding-bottom: 0;
            height: 24px;
            width: 395px;
            margin-top: 30px;
          }
        }
        &.pickupCollectionMethodLabel {
          font-size: 24px;
        }
      }

      .add-payment-method {
        padding-left: 40px;
        padding-right: 0;
        margin-top: 40px;
        height: 25px;
      }

      .info-label {
        padding-left: 40px;
        padding-right: 0;
        margin-top: 40px;
      }

      .contact-label {
        padding-left: 40px;
        padding-right: 0;
        margin-top: 38px !important;

        .btn-margin {
          margin-top: 22px;

          &.btn-margin-in {
            font-family: 'National 2 Regular';
            text-transform: none;
            padding: 0.5rem 1rem;
            border-radius: 200px;
            line-height: 1;
            border: 2px solid #000;
            font-weight: 700;
          }
        }

        @media screen and (max-width: 575px) {
          padding-left: 20px;
        }
      }

      .deliveryInfoContent {
        margin-top: 20px;
        margin-bottom: 40px;

        @include normalFont();

        font-size: 14px;

        button {
          @include normalFont();
          text-decoration-skip-ink: none;

          font-size: 14px;
          text-decoration: underline;
          font-weight: bold;
          padding: 0;
          box-shadow: none;
          color: #202124;
          border: none;
          background: $Mashed-Potato-Gray;
        }

        .addressBox {
          color: $Brand-Black;
          width: 80%;

          .addressField {
            display: flex;
            flex-direction: column-reverse;
            .form-control:focus {
              border-color: #ced4da !important;
            }
            input {
              border-width: 0 0 2px;
              background: $Mashed-Potato-Gray;
              box-shadow: none;
              border-radius: 0;
              padding: 0;
              font-size: small;
            }

            label {
              color: $Brand-Black;
              margin-top: 16px;
              margin-bottom: 0px;

              @include normalFont();
            }
          }
        }

        .sceduledAt {
          color: $Brand-Black;

          label {
            font-weight: bold;
            margin-right: 0.625rem;
          }
        }
      }

      .MuiPaper-outlined {
        border: none;
      }
    }

    .checkoutAmountDetails {
      @include payButtonMixin();
      margin-top: 24px;
      margin-bottom: 3.5rem;
      #payment-place-order {
        margin: 1.4rem 0rem 0rem;
      }
      &.mobile {
        color: rgba(0, 0, 0, 0.54);
        background-color: #f8f7f5;
      }

      .checkout-summary {
        .price-summary-item-title {
          @include commonFonts();

          letter-spacing: 0.5px;
          font-weight: bold;
          line-height: 24px;
          margin-bottom: 1.25rem;
        }

        .checkout-summary-item-count {
          @include commonFonts();

          letter-spacing: 0.5px;
          font-weight: bold;
          font-size: 24px;
          line-height: 24px;
          margin-bottom: 1.25rem;
        }

        .flexBetweens {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          margin-bottom: 6px;
        }

        .checkout-summary-coupons {
          margin-bottom: 0.625rem;
        }

        .checkout-summary-content {
          border-bottom: 1px solid $gray-lighter;
          padding-bottom: 0.5rem;
          margin-bottom: 0.5rem;
          margin-top: 15px;

          .bottom-line {
            border-bottom: 1px solid $gray-lighter;
            padding-bottom: 0.5rem;
            margin-bottom: 0.5rem;
            margin-top: 0.5rem;
          }
          &-item {
            &-name {
              font-family: $National2Regular;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 24px;
              color: $Dark-Gray;

              .cart-discount-code {
                white-space: nowrap;
                font-weight: 500;
                margin-right: 5%;
              }

              .cart-discount-clear-icon {
                vertical-align: middle;
                cursor: pointer;
              }

              &-total {
                font-family: $National2Condensed;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                color: $Brand-Black;
              }
            }

            &-value {
              font-family: $National2Regular;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 24px;
              color: $Brand-Black;

              &-total {
                font-family: $National2Condensed;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                color: $Brand-Black;
              }
            }
          }
        }

        .checkout-add-hope-container {
          display: flex;
          font-size: small;
          margin-bottom: 20px;
          border: 1px solid #e3e3e3;
          border-radius: 3px;
          padding: 10px 16px;

          .checkout-add-hope-checkbox {
            margin-top: 5px;
            margin-right: 5px;
          }

          .checkout-add-hope-content {
            margin-right: 5px;
            margin-left: 5px;
            width: 100%;

            .checkout-add-hope-header {
              width: 159px;
              font-family: $National2Regular;
              font-style: normal;
              font-weight: 900;
              font-size: 14px;
              line-height: 24px;
              color: $Brand-Black;
            }

            .checkout-add-hope-text {
              width: 163px;
              font-family: $National2Regular;
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 22px;
              color: $Dark-Gray;
            }
          }
        }

        .checkout-add-bag-container {
          display: flex;
          font-size: small;
          border-bottom: 1px solid #e3e3e3;
          padding-bottom: 1.25rem;
          margin-bottom: 1.25rem;

          .checkout-add-bag-checkbox {
            margin-top: 5px;
            margin-right: 5px;
          }

          .checkout-add-bag-content {
            margin-right: 5px;
            margin-left: 5px;
            width: 100%;

            .checkout-add-bag-text {
              font-family: $National2Regular;
              font-style: normal;
              font-weight: 900;
              font-size: 14px;
              line-height: 24px;
              color: $Brand-Black;
            }
          }
        }
      }

      .priceCard.mobile {
        margin: -4% -2%;
      }
    }

    .deliveryOptionsContent {
      @include normalFont();

      padding-left: 34px;
      margin-bottom: 20px;

      @media screen and (max-width: 575px) {
        padding-left: 23px;
        padding-right: 20px;
      }

      .flex-column {
        margin-top: 40px;

        @media screen and (max-width: 575px) {
          margin-top: 22px;
        }
      }

      .MuiFormControlLabel-root {
        align-items: flex-start;
      }

      .leaveAtMyDoorTitle,
      .handItToMe {
        font-family: $National2Medium;
        font-weight: 500;
        color: $Brand-Black;
        font-size: 14px;
        line-height: 24px;
        margin-top: 2px;
        margin-bottom: 13px;

        @include normalFont();
      }

      span {
        @include normalFont();
      }

      .MuiRadio-root {
        padding: 0 0.625rem;
      }

      .MuiRadio-colorSecondary.Mui-checked {
        color: #202124;
      }

      .MuiRadio-colorSecondary.Mui-checked:hover {
        background-color: unset;
      }

      .MuiIconButton-colorSecondary:hover {
        background-color: unset;
      }

      .deliveryInstrs {
        .MuiInputLabel-outlined {
          @include normalFont();
        }

        textarea::placeholder {
          @include normalFont();

          font-weight: normal;
          font-size: 13px;
          line-height: 24px;
          letter-spacing: -0.1px;
          color: #202124;
          opacity: 0.5;
        }

        .textarea {
          width: 100%;
          background: $Mashed-Potato-Gray;
          box-shadow: none;
          border: 1px solid #494949;
          box-sizing: border-box;
          border-radius: 3px;
          resize: none;
          padding: 10px 13px 9px 0px;
          margin-bottom: 0px;
          overflow: hidden;
          height: 40px;
          padding-inline: 5px;
        }
      }

      .pickupInstrs {
        .field.material-input {
          padding-top: 20px;
        }
        .material-input.field input {
          font-weight: bold;
        }
      }
      .deliveryInstructions {
        margin-top: 0px;
        input#mt-input-deliveryInstructions {
          font-weight: bold;
        }
        input#mt-input-orderNote {
          font-weight: bold;
        }
      }
      .pickupMethods {
        cursor: pointer;
        padding: 20px 5px !important;

        .radioBtns {
          margin-bottom: 0;

          label::before {
            top: 0;
          }

          input[type='radio']:checked + label::after {
            content: ' ';
            appearance: none;
            width: 10px;
            height: 10px;
            margin: 3px;
            background: $Brand-Black;
            border-radius: 100%;
            cursor: pointer;
            outline: none;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            top: auto;
            left: auto;
          }

          .leaveAtMyDoorTitle {
            display: inherit;
            margin-bottom: 0;
          }
        }

        .radioBtns .pickupCollectionMethod {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: nowrap;
          margin-left: 10px;

          &::before {
            content: ' ';
            margin: 0;
            appearance: none;
            height: 16px;
            width: 16px;
            background-color: transparent;
            border-radius: 100%;
            cursor: pointer;
            outline: none;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $Dark-Gray !important;
            box-sizing: border-box;
          }

          .methodIcon {
            margin: 0 15px;
          }

          .leaveAtMyDoorTitle {
            display: block;
            line-height: 24px;
            padding-top: 0;
            margin-top: 0;
            width: 170px;

            span {
              font-size: 16px;
            }

            .curbSidePickUp {
              .material-input {
                position: relative;
              }

              .material-input.field input {
                padding-bottom: 4px !important;
              }

              .material-input.field input:placeholder-shown + label {
                font-family: $National2Regular;
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0;
                text-align: left;
                max-width: 70%;
              }

              .material-input.field input:focus + label {
                font-size: 15px;
                line-height: 24px;
              }
            }
            .carNumberCurbside {
              background-color: $white;
              font-size: 14px;
              padding-bottom: 2px;
            }

            .vehicleNumber {
              font-size: 14px;
            }

            .vehicleNumber::before {
              display: none;
            }
          }
        }
      }

      .radioBtns {
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 10px;
        position: relative;

        label {
          margin-bottom: 0;
        }

        label:first-child {
          margin-bottom: 1rem;
        }

        input[type='radio'] {
          display: none;
        }

        label::before {
          content: ' ';
          display: inline-block;
          position: relative;
          top: 5px;
          margin: 0 5px 0 0;
          width: 20px;
          height: 20px;
          border-radius: 11px;
          border: 2px solid #202124;
          background-color: transparent;
        }

        input[type='radio']:checked + label::after {
          border-radius: 11px;
          width: 12px;
          height: 12px;
          position: absolute;
          top: 9px;
          left: 4px;
          content: ' ';
          display: block;
          background: #202124;
        }
      }

      .option-note {
        font-family: $National2Regular;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.1px;
        color: #494949;
      }

      .handItToMe-hr {
        border-top: 1px solid rgba(73, 73, 73, 0.5);
        margin-bottom: 10px;
      }

      .leaveatmydoor-radiobutton {
        display: none;
      }

      .handittome-radiobutton {
        .deliveryInstrs-error {
          color: #ff2e00 !important;
          font-size: 14px;
          font-family: $National2Medium;
          margin-top: -18px;
          margin-bottom: 20px;
          margin-left: 14px;
        }
      }
    }

    .contactInfoContent {
      .mandatory-note {
        font-family: $National2Regular;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0px;
        color: #494949;
        // margin: 0px;
        // padding-top: 5px;
        text-align: right;
      }

      .material-input {
        margin-bottom: 0;
        margin-top: 12px;
      }

      .common_checkbox {
        @include commonChecks();
        margin-left: auto;
      }
      .mandatory-checkbox-error {
        border-bottom: none !important;
      }
      @-moz-document url-prefix() {
        .common_checkbox {
          margin-top: -20px;
        }
      }
      .formElement {
        background: $Mashed-Potato-Gray;
        label,
        input {
          @include normalFont();

          font-size: 14px;
        }
      }

      .formElement-error {
        padding-top: 7px;
        background: $Mashed-Potato-Gray;
        border-bottom-color: #ff2e00;
        color: #ff2e00;
      }

      @-moz-document url-prefix() {
        .formElement-error::-moz-placeholder,
        .formElement::-moz-placeholder {
          color: transparent;
        }
      }

      .text-danger {
        color: #ff2e00 !important;
        font-size: 14px;
        font-family: $National2Medium;
        margin-top: 2px;
      }

      .agreeTxt {
        @include normalFont();

        font-size: 14px;
      }

      margin-top: 40px;
      margin-bottom: 40px;

      input.form-check-input {
        height: 16px;
        width: 16px;
      }

      input[type='checkbox']:focus {
        border-color: black;
      }
    }

    .addPaymentContent {
      @include normalFont();

      .flex-column {
        margin-top: 40px;
        margin-bottom: 40px;
      }

      .payButton {
        font-family: $National2Medium;
        text-align: right;
        text-decoration: underline;
        cursor: pointer;
        color: #202124;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        font-style: normal;
        border: none;
        background-color: #f8f7f5;

        &.payButton-in {
          padding-right: 10px;
          &::after {
            box-shadow: none;
          }
          &:hover {
            &::before {
              opacity: 0;
            }

            &::after {
              opacity: 0;
            }

            &:active {
              &::after {
                opacity: 0;
              }
            }
          }
          &:active {
            &::before {
              opacity: 0;
            }
          }
        }
      }

      &.selectedPayment {
        cursor: pointer;
      }

      .paytmPaymentProcess {
        font-size: 14px;
        color: #494949;
        letter-spacing: -0.1px;
      }

      @media screen and (max-width: 576px) {
        padding: 0;

        .flex-column {
          margin-right: 15px;
          margin-left: 15px;
        }
      }
    }
  }

  .divider {
    background-color: rgba(0, 0, 0, 0.12);
  }

  .checkoutIcons {
    color: $Brand-Black;
    margin-right: 0.625rem;
    margin-left: 7.5px;
  }

  .card {
    padding: 10.6%;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.625rem;
    box-shadow: 0 4px 9px rgba(0, 0, 0, 0.15);

    @media #{$mobile-only} {
      padding: 8% 4% !important;
    }

    .cart-summary-checkout-button {
      width: 100%;
      display: flex;
      justify-content: space-between;
      border: none;
    }

    .cart-summary-place-order {
      width: 100%;
      border: none;
    }
  }

  .center-element {
    margin: auto;
    width: 100%;
  }

  .wd-max-content {
    width: max-content;
  }

  .m-auto {
    margin: auto;
  }

  .mt-40px {
    margin-top: 2.5rem;
  }

  .mt-20px {
    margin-top: 1.25rem;
  }

  .mt-10px {
    margin-top: 0.625rem;
  }

  .pl-0px {
    padding-left: 0px !important;
  }

  .pl-10px {
    padding-left: 0.625rem;
  }

  .pl-25px {
    padding-left: 1.625rem;
  }

  .pr-10px {
    padding-right: 0.625rem;
  }

  .pr-25px {
    padding-right: 1.625rem;
  }

  .px-10px {
    padding: 0 0.625rem;
  }

  .p-10px {
    padding: 0.625rem;
  }

  .px-25px {
    padding: 0 1.625rem;
  }

  .px-rt-5px {
    padding-right: 5px !important;
  }

  .py-25px {
    padding: 1.625rem 0;
  }

  .p-25px {
    padding: 1.625rem;
  }

  .ml-25px {
    margin-left: 25px;
  }

  .mb-20px {
    margin-bottom: 20px;
  }

  .mb-30px {
    margin-bottom: 30px;
  }

  .mt-40px {
    margin-top: 40px;
  }

  .pl-0px,
  .pr-0px {
    padding-left: 0;
    padding-right: 0;
  }

  &.MuiContainer-root.mobile {
    padding: 0;
  }

  .divider {
    background-color: rgba(0, 0, 0, 0.12);
  }

  .checkoutIcons {
    color: $Brand-Black;
    margin-right: 0.625rem;
    margin-left: 7.5px;
  }

  .card {
    padding: 40px;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.625rem;
    box-shadow: 0 4px 9px rgba(0, 0, 0, 0.15);

    .cart-summary-checkout-button {
      width: 100%;
      display: flex;
      justify-content: space-between;
      border: none;
    }

    .cart-summary-place-order {
      width: 100%;
      border: none;
    }
  }

  .mt-20px {
    margin-top: 1.25rem;
  }

  .mt-10px {
    margin-top: 0.625rem;
  }

  .pl-10px {
    padding-left: 0.625rem;
  }

  .pl-25px {
    padding-left: 1.625rem;
  }

  .pr-10px {
    padding-right: 0.625rem;
  }

  .pr-25px {
    padding-right: 1.625rem;
  }

  .px-10px {
    padding: 0 0.625rem;
  }

  .p-10px {
    padding: 0.625rem;
  }

  .px-25px {
    padding: 0 1.625rem;
  }

  .py-25px {
    padding: 1.625rem 0;
  }

  .p-25px {
    padding: 1.625rem;
  }

  .ml-25px {
    margin-left: 25px;
  }

  .mb-20px {
    margin-bottom: 20px;
  }

  .mt-40px {
    margin-top: 40px;
  }

  .pl-0px,
  .pr-0px {
    padding-left: 0;
    padding-right: 0;
  }
}

.payment-form-credit-card-container {
  .card-number-image {
    position: relative;
    float: right;
    top: -43px;
    right: 10px;
  }
}

.tender-type-label {
  cursor: pointer !important;

  input {
    margin-right: 10px;
  }
}

.payment-option-container {
  .radioBtnsOption {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 10px;
    position: relative;

    span {
      margin-bottom: 0;
    }

    span:first-child {
      margin-bottom: 1rem;
    }

    input[type='radio'] {
      display: none;
    }

    span::before {
      content: ' ';
      display: inline-block;
      position: relative;
      top: 3px;
      margin: 0 10px 0 0;
      width: 16px;
      height: 16px;
      border-radius: 11px;
      border: 1px solid #494949;
      background-color: transparent;

      @supports (-webkit-backdrop-filter: blur(1px)) {
        top: 2px;
      }
    }

    input[type='radio']:checked + span::after {
      border-radius: 11px;
      width: 10px;
      height: 10px;
      position: absolute;
      top: 5.8px;
      left: 3px;
      content: ' ';
      display: block;
      background: #202124;
      // @media #{$breakpoint-iphone-ios15} {
      // 	padding: 5px 10px !important;
      // }
    }

    input[type='radio']:checked + .phonePay::after {
      top: 12px;
    }

    @-moz-document url-prefix() {
      input[type='radio']:checked + .googlePay::after {
        top: 7px;
      }
    }
  }

  .payment-option {
    margin: 10px 0;
    padding: 5px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    transition: 150ms;

    .payment-option-header {
      padding: 10px;

      .tender-type-label {
        cursor: pointer !important;
        margin-bottom: 0;
        margin-left: 25px;
        font-family: $National2Medium;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #202124;

        input {
          margin-right: 13px;
        }
      }
    }

    .payment-option-child {
      display: none;
      padding: 10px;
      transition: 150ms;
      padding-top: 0;

      &.active {
        display: block;
      }
    }
  }
}

.col-12.payment-form-credit-card-container {
  padding-left: 26px;

  .check-balance {
    margin: 5px 29%;

    .col-12 {
      width: 300px;
      padding-left: 0;
      padding-right: 0;

      button {
        line-height: 0px;
        margin-left: 0;

        @media screen and (max-width: 668px) {
          padding-left: 12px;
          padding-right: 9px;
          font-size: 12px;
        }

        @media screen and (max-width: 426px) {
          padding-top: 5px;
          line-height: 1rem;
        }
      }
    }
  }
}

.payment-failed-error-box {
  display: flex !important;
  flex-wrap: unset !important;
  flex-direction: row !important;
  align-items: flex-start !important;
  background-color: $Red-Orange5 !important;
  border-radius: 4px;
  font-family: $National2Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: $Red-Orange;
  padding: 15px 20px 20px 20px;
}

.ml-10px {
  margin-left: 10px;
}

.mr-10px {
  margin-right: 10px;
}

.mt-3px {
  margin-top: 3px;
}

.mt-0px-imp {
  margin-top: 0px !important;
}

.mr-15px-imp {
  margin-right: 15px !important;
  @media #{$breakpoint-between-mob-tablet} {
    margin-right: -227px !important;
    margin-top: -65px !important;
  }
}

.p-0px-imp {
  padding: 0px !important;
}

.pr-10px-imp {
  padding-right: 10px !important;
}

.pr-15px-imp {
  padding-right: 15px !important;
}

.txt-align-center {
  text-align: center !important;
}

.global-accordian-container.billDropDown {
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;

  .global-accordian-header-container {
    height: 60px;
    padding: 15px 5px;

    .global-accordian-header {
      font-family: 'National 2 Condensed';
      color: $Brand-Black;
      font-weight: 700;
    }

    .global-accordian-arrow {
      width: 16px;
      height: 12px;
    }
  }
}
.wicode-summary-checkout-notification-meal-fully-covered {
  display: flex;
  flex-direction: column;
  @media #{$mobile-only} {
    // margin: 0 5% 3% 0;
  }
  @media #{$breakpoint-below-tablet} {
    width: 100%;
  }
  .notify-alert-wicode {
    background: $light-green;
    @include notify-alert-wicode();

    @media #{$breakpoint-below-tablet} {
      margin-left: 0px;
      width: 100%;
      padding: 15px;
    }

    & .location-alert-icon {
      @include alert-icon;

      @media #{$breakpoint-below-tablet} {
        margin: -1.5px 8px 4px 0px;
      }
    }
    .promos-error-massage {
      color: #494949;
    }
  }
}

.reducedTaxRequiredText {
  @include normalFont();
  font-weight: normal;
}

.kpaymentQRcodeFormShow {
  transition: 0.25s !important;
  transition-timing-function: ease-out !important;
  opacity: 1 !important;
  visibility: visible !important;
}
