.coupon-list {
  background-color: $Brand-Black;
  color: $white;
  padding: 0 16px 24px;

  @media #{$breakpoint-above-tablet} {
    padding: 0 16px 40px;
  }

  &.horizontal {
    margin-bottom: 0;

    .coupon-wrapper.horizontal {
      flex-wrap: nowrap;
      width: auto;
      overflow-x: auto;
      &.less-margin {
        margin-left: -10px;
      }
    }

    .coupons {
      flex-wrap: nowrap;
    }

    .coupon {
      flex-basis: 43%;
      max-width: 43%;
      min-width: 43%;
      opacity: 1;
      -webkit-transition: opacity 0.5s ease-in-out;
      -moz-transition: opacity 0.5s ease-in-out;
      transition: opacity 0.5s ease-in-out;
      max-height: 335px;

      &.faded {
        opacity: 1;
      }

      &.extra-margin {
        margin-left: 10px;
      }

      @media #{$breakpoint-above-tablet} {
        flex-basis: 22%;
        max-width: 22%;
        min-width: 22%;
      }
    }
  }

  &.single {
    background-color: $Mashed-Potato-Gray;
    display: inline-block;
    margin-bottom: 0;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;

    .flexible-component {
      width: 100%;
      max-width: 1140px;
      margin: 0 auto;

      &--header {
        &-wrapper {
          padding: 16px 0 16px 0;
          @media #{$breakpoint-above-tablet} {
            padding: 40px 0 24px 0;
            font-size: 40px;
          }
        }

        &-copy {
          justify-content: center;
        }
        &-title {
          color: $Brand-Black;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          letter-spacing: 0.5px;
          margin-bottom: 0;
          @media #{$breakpoint-above-tablet} {
            font-size: 40px;
            letter-spacing: 1px;
          }
        }
      }

      &--sub-header-copy {
        color: $Brand-Black;

        @media #{$breakpoint-above-tablet} {
          text-align: center;
        }
      }
    }

    .coupons {
      display: flex;
      flex-wrap: nowrap;
      gap: 2px;
      margin: 0;
      overflow: scroll;
      overflow-x: auto;
      overflow-y: hidden;
      scroll-snap-type: x mandatory;
      scroll-behavior: smooth;
      -webkit-overflow-scrolling: touch;
      box-shadow: 4px 4px 8px -2px rgba(0, 0, 0, 0.5);
      border-radius: 8px;

      @media #{$breakpoint-above-tablet} {
        gap: 1px;
      }

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }

    .coupon {
      max-height: 440px;
      flex-shrink: 0;
      flex-basis: unset;
      max-width: none;
      position: relative;
      scroll-snap-align: start;
      transform-origin: center center;
      transform: scale(1);
      transition: transform 0.5s;
      width: 100%;

      @media #{$breakpoint-above-mobile} {
        height: 335px;
      }
    }
  }

  .coupons {
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    row-gap: 32px;
    align-items: stretch;
    justify-content: flex-start;

    @media #{$breakpoint-above-tablet} {
      gap: 24px;
      row-gap: 32px;
    }
  }

  .coupon {
    position: relative;
    width: 100%;
    max-width: 23.5%;

    @media #{$breakpoint-below-tablet} {
      max-width: 31.5%;
    }

    @media #{$mobile-only} {
      max-width: 47.5%;
    }

    @media #{$breakpoint-below-smallest-mobile} {
      max-width: 100%;
    }

    &--wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      overflow: hidden;
      text-align: left;
      border-radius: 8px;

      cursor: pointer;
    }

    &--image-container {
      position: relative;
      overflow: hidden;
    }

    &--background-image {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &--image {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;

      img {
        width: 90%;
      }
    }

    .zoom-in {
      transition: 0.3s transform ease-in-out;
      &:hover {
        transform: scale(1.2);
        transition: 0.3s transform ease-in-out;
      }
    }

    &--info {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 16px 24px 24px;
      color: $Brand-Black;
      background-color: $Mashed-Potato-Gray;

      &.no--image {
        padding: 48px 24px 24px;
      }

      @media #{$mobile-only} {
        padding: 16px 8px 16px;
      }
    }

    &--title {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      font-family: $National2Condensed;
      font-size: 20px;
      font-weight: $font-weight-bold;
      line-height: 1;
      overflow-wrap: break-word;
      text-transform: uppercase;

      @media #{$breakpoint-above-tablet} {
        font-size: 24px;
      }
    }

    &--price {
      font-family: $National2Medium;
      font-weight: 500;
      font-size: 16px;
      line-height: 1.5;
      margin-bottom: 8px;

      .strikethrough {
        color: $Brand-Red;
        padding-right: 8px;
      }
    }

    &--enddate {
      font-family: $National2Regular;
      font-size: 16px;
      margin-bottom: 0;
    }

    &--disposition {
      align-items: center;
      display: flex;
      flex-direction: row;
      font-family: $National2Condensed;
      font-size: 14px;
      font-weight: $font-weight-bold;
      letter-spacing: 0.5px;
      line-height: 1;
      margin-bottom: 8px;
      text-transform: uppercase;

      @media #{$breakpoint-above-tablet} {
        font-size: 16px;
      }

      &_icon {
        display: inline-block;
        margin-right: 4px;
      }
    }

    &--active-label {
      align-items: center;
      background: $light-green;
      border-radius: 4px;
      display: flex;
      font-family: $National2Regular;
      font-weight: $font-weight-500;
      font-size: 14px;
      line-height: 1.5;
      margin: 8px 0 0;
      padding: 4px 8px;
      width: max-content;

      &_icon {
        display: inline-block;
        height: 16px;
        margin-right: 3px;
        width: 16px;
      }
    }
  }
}
